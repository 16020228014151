.campaign-name {
    font-size: 28px !important;
}

.campaign-table-image {
    width: 50px;
    height: auto;
}

.channel-icon img {
    width: 50px;
    height: auto;
    /* margin-right: 24px; */
    /* filter: blur(.8px); */
}

.channel-icon img:hover {
    filter: blur(0px);
}

button.channel-icon-btn {
    background: transparent;
    border: none;
    padding: 6px;
    width: 94px;
    border-radius: 9px;
}

.campain-view-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.campain-view-header-subtitle {
    display: flex;
    align-items: center;
}

.campain-view-header-subtitle p {
    margin-right: 24px;
    margin-bottom: 0;
    font-size: 20px;
    color: #5d586c;
    font-weight: 600;
}

.campain-view-header-subtitle p svg {
    margin-right: 6px;
}

.campain-view-details img {
    width: 18px;
    margin-right: 8px;
}

.campain-view-details {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.campain-time {
    margin: 8px 0px 0px;
}

.campain-time p {
    margin-bottom: 6px;
    color: #5d586c;
}

.cam-paigns .dropdown {
    width: 100%;
} 

button.download-csv-btn {
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #5d586c;
}

button.download-csv-btn svg {
    margin-right: 6px;
}

.campaigns-table-image img {
    width: 55px;
}

.campaign-view-qr-code {
    padding: 1rem 0;
}

.campaign-table-image2 {
    width: 50px;
    height: auto;
    margin-right: 24px;
}

.create-text {
    font-size: 20px;
    color: #6237e4;
    font-weight: 600;
}

.channel_img_list {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.inner-icon-list {
    display: flex;
    gap: 14px;
    margin-top: 12px;
}

.inner-icon-list img {
    width: 24px;
    height: 24px;
}

.views02 {
    display: flex;
    align-items: center;
    gap: 30px;
}

.wrap-campion {
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
}

.campion-dropdown.dropdown {
    width: -webkit-fill-available;
}

.XZYPL {
    display: flex;
    gap: 5px;
}

button.close-btn-cmp {
    background: #f64e60;
    color: #fff;
    border: 1px solid #f64e60;
    height: -webkit-fill-available;
    width: 44px;
    border-radius: 5px;
    padding: 0;
}

button.close-btn-cmp svg {
    width: 24px;
    height: 24px;
}

.campaign-dropdown.dropdown {
    width: auto;
}

.campain-view-header-title h3 {
    color: #5d586c;
}

.campain-view-header p {
    color: #5d586c;
}

.campagin-name-view {
    margin: 10px 0px;
}

button.btn-edit:disabled {
    border: none;
    opacity: .65 !important;
}

button.btn-chart:disabled {
    border: none;
    opacity: .65 !important;
}

@media (max-width: 673px) {
    .campain-view-details {
        margin-top: 20px;
    }

    .campaign-table-image {
        width: 30px;
        height: auto;
        margin-right: 0px;
    }
}

@media (max-width: 375px) {
    .campaign-table-image {
        width: 30px;
        height: auto;
        margin-right: 0px;
    }

    .campaign-table-image2 {
        width: 40px;
        height: auto;
        margin-right: 0px;
    }
}

@media (min-width: 1201px) and (max-width: 1920px) {
    .hide-desktop {
        display: none;
    }
}