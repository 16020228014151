/* Kanban Board Styles */
.deals-kanban-page {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.kanban-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.search-export {
    display: flex;
    gap: 10px;
}

.search-input {
    min-width: 240px;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.export-btn, .add-deal-btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-weight: 500;
}

.export-btn {
    background-color: #f1f1f1;
    color: #333;
}

.add-deal-btn {
    background-color: #0d6efd;
    color: white;
}

.kanban-board {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 16px;
    height: calc(100vh - 160px);
}

.kanban-column {
    min-width: 280px;
    width: 280px;
    background-color: #f5f5f5;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.column-header {
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f0f0f0;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.column-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.deal-count {
    background-color: #e0e0e0;
    color: #333;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
}

.column-content {
    padding: 12px;
    flex-grow: 1;
    overflow-y: auto;
    min-height: 100px;
}

.column-content.dragging-over {
    background-color: rgba(144, 202, 249, 0.2);
}

.deal-card {
    background-color: white;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: grab;
    transition: all 0.15s ease;
}

.deal-card:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.deal-card.dragging {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
}

.deal-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.deal-title {
    margin: 0;
    font-size: 15px;
    color: #0066cc;
    text-decoration: none;
    cursor: pointer;
    flex-grow: 1;
}

.deal-title:hover {
    text-decoration: underline;
}

.delete-btn {
    background: none;
    border: none;
    font-size: 18px;
    color: #999;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
}

.delete-btn:hover {
    color: #ff4444;
}

.deal-info {
    font-size: 13px;
    color: #666;
}

.deal-amount {
    font-weight: 600;
    color: #28a745;
    margin-bottom: 4px;
}

.deal-date, .deal-owner {
    margin-bottom: 2px;
}

/* Scrollbar styling for the column content */
.column-content::-webkit-scrollbar {
    width: 6px;
}

.column-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.column-content::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.column-content::-webkit-scrollbar-thumb:hover {
    background: #bbb;
}