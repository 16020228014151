.tab-content-body {
    background: #fff;
    padding: 18px;
}

.tab-card {
    padding-right: 10px;
}

.bx-1 {
    border-left: 1px solid #D9D9D9;
}

.tab-card-header {
    justify-content: space-between;
    align-items: baseline;
}

h5.tab-card-title {
    font-size: 20px;
    font-weight: 700;
}

.action-btn {
    color: #fff !important;
    padding: 8px 34px;
    margin-right: 12px;
    border-radius: 6px;
}

.action-btn:hover {
    color: #fff;
}

.btn-green:hover {
    background: #7367F0 !important;
}

.btn-red:hover {
    background: #FF0000 !important;
}

.nav-pills .technician-tab-link.active,
.nav-pills .show>.technician-tab-link,
.nav-pills .technician-tab-link:hover {
    color: #1136df;
    background-color: transparent !important;
}

.nav-pills .technician-tab-link.active .user-profile-box,
.nav-pills .technician-tab-link:hover .user-profile-box {
    background: #1136df;
    color: #fff;
}

.nav-pills .technician-tab-link:hover .user-profile-box svg {
    animation: swing ease-in-out 0.6s 1 alternate;
}

.user-profile-box {
    background: #F1F0F2;
    padding: 6px 8px;
    border-radius: 6px;
    width: 40px;
    height: 40px;
}

.brand-Vanity-Tag p {
    font-size: 14px;
}

.nav-pills .technician-tab-link {
    display: flex;
    align-items: center;
    padding: 8px 0px;
    color: #5D586C;
    transition: 0.4s;
}

.profile-image {
    width: 26px;
    height: 26px;
}

span.tab-user-name {
    margin-left: 16px;
    font-size: 15px;
    font-weight: 600;
    display: block;
}

.tab-content-header {
    margin-left: 18px;
}

.tab-content-body .nav-tabs {
    border-bottom: 1px solid #D9D9D9;
}

.tab-content-body .nav-tabs .nav-item .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    width: 180px;
    transition: 0s;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid transparent;
}

.tab-content-body .nav-tabs .nav-item .nav-link:hover {
    border: none;
    border-color: transparent;
}

.tab-content-body .nav-tabs .nav-item .nav-link.active {
    color: #7367F0;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #7367F0;
    width: 180px;
    background: transparent;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-grow: 0;
}

.form-btn-right {
    display: flex;
    justify-content: end;
    /* margin-top: 20px; */
}

.form-btn-right .action-btn {
    margin-right: 0px;
}

.card-custom {
    padding: 24px 0px;
}

.schedule-list-item-inner {
    border: 1px solid #D9D9D9;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.schedule-list-item-inner-right-top {
    display: flex;
    align-items: center;
}

.spacing {
    margin: 0px 8px;
}

.schedule-list-item-inner-left-top-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

.add-offtime-row {
    align-items: end;
}

.off-time-detail-btn {
    float: right;
}

.off-time-detail-btn .action-btn {
    margin-right: 0px;
}

.user-profile-box svg {
    width: 24px;
    height: 24px;
}

.add-btn {
    justify-content: space-between !important;
    align-items: center;
}

.payment-list-item {
    border: 1px solid #DBDADE;
    border-radius: 6px;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-list-title h4 {
    font-size: 19px;
    margin-bottom: 0px;
    font-weight: 600;
}

.custom-accordion-item {
    border-top: 1px solid #DBDADE !important;
    border-bottom: 1px solid #DBDADE !important;
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.accordion-button {
    color: #6F6B7D;
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    color: #6F6B7D;
    background-color: #fff;
    border-color: #DBDADE;
}

.accordion-button:focus {
    box-shadow: none;
}

.setting-btn {
    color: #fff !important;
    padding: 8px 20px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 8px;
}

.setting-btn:hover {
    color: #fff;
}

.bg-green-dark:hover {
    background-image: var(--btncolor) !important;
}

.bg-lightblue-dark {
    background: #8178DB !important;
}

.bg-lightblue-dark:hover {
    background: #8178DB !important;
}

.bg-purple-dark {
    background: #B31799 !important;
}

.bg-purple-dark:hover {
    background: #B31799 !important;
}

.tab-content-title {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.tab-content-title .tab-content-icon {
    font-size: 24px;
    margin-right: 7px;
}

.setting-profile-img {
    width: 96px;
    height: auto;
}

.setting-profile,
.setting-profile-content {
    margin: 0px 12px;
}

.setting-profile-content h3 {
    margin-bottom: 0px;
    color: #5D586C;
    font-size: 18px;
    font-weight: 600;
}

.setting-profile-content h6 {
    margin-bottom: 0px;
    color: #5D586C;
    font-size: 20px;
}

.setting-profile-email,
.setting-profile-address,
.setting-profile-joindate {
    margin-right: 24px;
}

.setting-profile-content h6 span {
    font-size: 15px;
}

.bg-cancel-btn {
    color: #5D586C !important;
    padding: 8px 34px;
    border-radius: 6px;
    background-color: #F9F9FA;
    font-weight: 500;
    border: 1px solid #F9F9FA;
}

.Member-list .member-img {
    width: 70px;
    height: auto;
    margin-right: 30px;
}

.Member-list .member-content h3 {
    font-size: 15px;
    font-weight: 700;
    color: #5D586C;
}

.Member-list .member-content h4 {
    font-size: 14px;
    font-weight: 500;
    color: #A5A2AD;
}

.Member-list .member-content p {
    font-size: 14px;
    font-weight: 500;
    color: #5D586C;
    margin-bottom: 0;
}

.modal-dialog.modal-dialog-centered {
    min-width: 600px;
}

.modal-dialog.modal-dialog-centered .modal-header {
    border-bottom: none;
}

.modal-dialog.modal-dialog-centered .modal-footer {
    border-top: none;
}

.modal-dialog.modal-dialog-centered .modal-title {
    font-size: 22px;
}

.modal-dialog.modal-dialog-centered .modal-title svg {
    margin-right: 10px;
}

.Subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Subscription-form-check h6 {
    margin-bottom: 0px;
}

.form-check-input:checked {
    background-color: #0b0fe8 !important;
    border: #0c11e8 !important;
}

.Subscription-batch h6 {
    margin-bottom: 22px;
    background-color: #7367f015;
    padding: 2px 8px;
    color: #7367f0;
    border-radius: 4px;
}

.Subscription-batch {
    margin-left: 240px;
}

.API-key-list ul,
.brand-list ul,
.Authentications-list ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.API-key-list ul li,
.brand-list ul li {
    margin-bottom: 16px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0px 0px 11px -3px #ccc;
    padding: 24px;
    background: #fff;
}

.API-key-list-item-left h3,
.Authentications-item-left h3 {
    font-size: 15px;
    font-weight: 600;
    color: #6D687A;
}

.API-key-list-item {
    margin-bottom: 16px;
}

.API-key-list-item-left h4,
.brand-content h4,
.Authentications-item-left h4 {
    font-size: 13px;
    font-weight: 500;
    color: #D2D0D6;
}

button.API-key-list-item-right-btn,
.Authentications-item-right-btn {
    font-size: 15px;
    font-weight: 600;
    color: #EA5455;
    background-color: #FBE3E4;
    border: 1px solid #FBE3E4;
    border-radius: 6px;
    padding: 10px 20px;
}

.Authentications-item-right-connect-btn {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background-color: #7367F0;
    border: 1px solid #7367F0;
    border-radius: 6px;
    padding: 10px 20px;
}

.API-key-details .API-key-details-left h5,
.API-key-details .API-key-details-right h5 {
    font-size: 15px;
    font-weight: 600;
    color: #6D687A;
    margin-bottom: 16px;
}

.brand-img {
    width: 50px;
    height: auto;
}

.brand-content h3 {
    font-size: 18px;
    font-weight: 600;
}

.profiledesign {
    display: flex;
    align-items: end;
    flex-direction: row;
}

.brand-content,
.Authentications-item-left {
    margin-left: 20px;
}

.brand-Vanity-Tag h3 {
    font-size: 15px;
    font-weight: 600;
    color: #7367F0;
    margin-bottom: 0;
}

.Brand-Description {
    margin-top: 16px;
}

.vanity-tag h4 {
    color: #fff;
    background-color: #7367F0;
    margin-left: 16px;
    font-size: 14px;
    border-radius: 4px;
    padding: 3px 6px;
    margin-bottom: 0;
    line-height: 22px;
    display: flex;
    align-items: center;
    width: max-content;
}

.vanity-tag h4:first-child {
    margin-left: 0;
}

.vanity-tag h4 svg {
    margin-right: 6px;
}

.vanity-tag a {
    color: #fff;
    background-color: #7367F0;
    margin-left: 6px;
    font-size: 14px;
    border-radius: 4px;
    padding: 3px 6px;
    margin-bottom: 0;
    line-height: 22px;
    font-weight: 500;
}

.Authentications-lmage {
    width: 30px;
    height: auto;
}

.Authentications-list ul li {
    margin-bottom: 24px;
}

.Add-margin {
    margin-bottom: 18px;
}

button.btn-edit {
    color: #fff !important;
    background-color: #0c15e7 !important;
    font-size: 24px;
    padding: 0px 8px 3px;
    opacity: 1 !important;
}

button.btn-delete {
    color: #fff !important;
    background-color: #f64e60 !important;
    font-size: 24px;
    padding: 0px 8px 3px;
}

button.btn-chart {
    color: #fff !important;
    background-color: #00CFE8 !important;
    font-size: 24px;
    padding: 0px 8px 3px;
}

button.btn-chart svg {
    color: #fff;
}

button.btn-layout {
    color: #fff !important;
    background-color: #24c080 !important;
    font-size: 24px;
    padding: 0px 8px 3px;
}

button.duplicate-btn {
    background: #ffa900 !important;
    color: #fff !important;
    font-size: 24px;
    padding: 0px 8px 3px;
    opacity: 1 !important;
}

button.move-btn {
    background: #aa89f8 !important;
    color: #fff !important;
    font-size: 24px;
    padding: 0px 8px 3px;
    opacity: 1 !important;
}

button.btn-layout-view {
    color: #fff !important;
    background-color: #ff1eee !important;
    font-size: 24px;
    padding: 0px 8px 3px;
}

.table-card-header.add-btn.d-flex {
    flex-wrap: wrap;
}

.new-effect:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
}

.form-control-color {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem;
}

.add-usar-btn {
    background-image: linear-gradient(45deg, #0b10e7, #2adb66) !important;
    border: none;
    height: 38px;
    padding: 0px 20px;
    font-size: 15px;
    font-weight: 500;
}

.add-usar-btn svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    color: #fff;
}

.API-key-details-left h5 span {
    margin-right: 10px;
}

.setting-tab-content-body {
    padding: 18px 0px 18px 18px;
}

.group-toggle-select .btn-group label {
    padding: 2px 10px;
    border-radius: 6px;
    margin-right: 20px;
}

.group-toggle-select .btn-group label {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border: 1px solid transparent !important;
    margin-bottom: 10px;
}

.group-toggle-select .btn-group label.radio-btn01 {
    background-color: rgba(234, 84, 85, 0.1);
    color: #EA5455;
}

.group-toggle-select .btn-check:checked+.btn.radio-btn01 {
    background-color: #EA5455;
    color: #fff;
}

.group-toggle-select .btn-group label.radio-btn02 {
    background-color: rgba(0, 207, 232, 0.1);
    color: #00CFE8;
}

.group-toggle-select .btn-check:checked+.btn.radio-btn02 {
    background-color: #00CFE8;
    color: #fff;
}

.group-toggle-select .btn-group label.radio-btn03 {
    background: rgba(115, 103, 240, 0.1);
    color: #7367F0;
}

.group-toggle-select .btn-check:checked+.btn.radio-btn03 {
    background-color: #7367F0;
    color: #fff;
}

.group-toggle-select .btn-group label.radio-btn04 {
    background: rgba(40, 199, 111, 0.1);
    color: #28C76F;
}

.group-toggle-select .btn-check:checked+.btn.radio-btn04 {
    background-color: #28C76F;
    color: #fff;
}

.help-btn-view.btn {
    margin-right: 10px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
}

.group-toggle-btn {
    opacity: 0.5;
}

.btn-check:checked+.group-toggle-btn {
    opacity: 1;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
}

.create-group-title {
    text-align: center;
    font-size: 55px;
    padding: 20px 0px;
}

.sec-bg-img {
    background-image: url(http://localhost:3000/static/media/Screenshot.57a2562db676f127c849.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: inherit;
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Member-list {
    margin-top: 18px;
}

@media (max-width: 1199.99px) {
    .tab-content-body {
        background: #fff;
        padding: 14px 6px;
    }

    span.tab-user-name {
        margin-left: 12px;
        font-size: 16px;
    }

    .tab-content-header {
        margin-left: 18px;
    }
}

@media (max-width: 991.98px) {
    .tab-content-header {
        margin-left: 18px;
    }

    .tab-content-title .tab-content-icon {
        font-size: 22px;
    }

    .add-usar-btn {
        padding: 0px 16px;
    }

    .tab-content-title {
        font-size: 16px;
    }
}

@media (max-width: 767.98px) {
    .modal-dialog.modal-dialog-centered {
        min-width: 544px;
    }

    .brand-content {
        margin-left: 10px;
    }

    .tab-content-header {
        margin: 0px 18px;
    }
}

@media (max-width: 575.98px) {
    .profiledesign {
        align-items: start;
        flex-direction: column;
    }

    .setting-profile-email,
    .setting-profile-address,
    .setting-profile-joindate {
        margin-right: 0px;
    }

    .tab-content-header.add-btn.d-flex {
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }

    .add-user-btn {
        margin: 0px;
    }

    .action-btn {
        padding: 6px 16px;
    }

    .custom-control.custom-checkbox.me-4 {
        margin-right: 10px !important;
    }

    .modal-dialog.modal-dialog-centered {
        min-width: 460px;
    }
}

@media (max-width: 479.98px) {
    .justify-mob01 {
        justify-content: space-between;
    }

    .modal-dialog.modal-dialog-centered {
        min-width: 304px;
    }

    .vanity-tag.d-flex {
        flex-wrap: wrap;
    }

    .Authentications-item-right-connect-btn,
    .Authentications-item-right-btn {
        font-size: 12px;
        padding: 4px 12px;
    }

    .download-qr-btn {
        padding: 8px 30px !important;
        font-size: 15px !important;
    }

    .action-btn,
    .bg-cancel-btn {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 600;
    }

    button.API-key-list-item-right-btn,
    .Authentications-item-right-btn {
        padding: 4px 12px;
    }

    .add-usar-btn svg {
        margin-right: 4px;
        width: 16px;
        height: 16px;
    }

    .add-usar-btn {
        font-size: 12px;
    }

    .API-key-details .API-key-details-left h5,
    .API-key-details .API-key-details-right h5 {
        font-size: 14px;
    }

    .API-key-details-left h5 span {
        margin-left: 6px;
    }

    .Member-list li.list-group-item.d-flex.align-items-center.justify-content-between {
        flex-wrap: wrap;
    }
}


@media (max-width: 375px) {
    .download-qr-btn {
        padding: 8px 24px !important;
        font-size: 12px !important;
    }
}




.imagePreview-profile {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}


.avatar-upload {
    position: relative;
    width: 150px;
    height: 150px;
}

.avatar-edit {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transform: translate(6px, -7px);
    color: #fff;
}

.edit-profile-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.avatar-edit input {
    display: none;
}

.avatar-edit label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.avatar-preview {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.password-footer {
    background: #fff;
    box-shadow: 0px 4px 18px rgba(75, 70, 92, 0.1);
    border-radius: 6px;
    padding: 18px;
    margin-top: 20px;
}

.password-footer p {
    margin-bottom: 0;
}

.api-details-list {
    display: none;
}

.api-details-list.active {
    display: block;
}