.left-side-content {
    width: 42%;
}

a {
    text-decoration: none;
}

.flow-page-title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.event_page .dropdown {
    width: 100%;
}

.left-side-content {
    padding: 0px 0px 0px 22px;
}

.img-fluid-event {
    max-width: 70%;
    height: auto;
}

.event-golle {
    color: rgb(99, 99, 99);
    border: 1px solid rgb(179, 179, 179);
    border-radius: 4px;
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
    padding: 5px 8px;
    text-align: center;
}

p.flow-page-subtitle {
    cursor: inherit;
    font-weight: normal;
    line-height: revert;
    color: rgb(91, 94, 99);
    font-size: 20px;
    margin: 16px 0px 24px;
}

.create-flow {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(3, 61, 221) !important;
    border-radius: 4px;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(3, 61, 221) !important;
    padding: 10px 24px;
    height: 48px;
    width: 100%;
    margin-right: 8px;
}

button.btn.learn-flow.bg-color {
    background: #500c5f;
    color: #fff;
    border: 1px solid #500c5f;
    border-radius: 3px;
}

.create-flow:hover {
    background-color: rgb(3, 61, 221);
    color: rgb(255, 255, 255);
}


.learn-flow {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid rgb(222, 223, 224);
    line-height: 1.28571;
    letter-spacing: 0.025rem;
    padding: calc(0.5625rem - 1px) calc(0.875rem - 1px);
    color: rgb(3, 61, 221);
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
    height: 48px;
    width: 100%;
}

.learn-flow:hover {
    background-color: rgb(222, 223, 224);
    color: rgb(3, 61, 221);
}

.btn-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.gride-content-text {
    margin-top: 12px;
}

p.gride-content-text-title {
    font-size: 15px;
}

.gride-content-inner {
    display: flex;
}

.page-flow-inner {
    display: flex;
    justify-content: space-between;
}

.right-side-img img {
    width: 70%;
}

.right-side-content-inner {
    background: #033ddd;
    padding: 40px;
}

.right-side-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

h5.title {
    margin: 14px 0px 24px;
}

.left-inner .icon svg {
    width: 40px;
    height: 40px;
}







/* new flow page css  */



.flowpage-item {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 10px;
}

.flowpage-item {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 10px;
}

.flowpage-item-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flowpage-item-inner-left {
    display: flex;
    gap: 20px;
}

.flowpage-qr-image {
    width: 130px;
    height: 130px;
}

.flowpage-qr-image img {
    width: 100%;
    height: 100%;
}

.flowpage-name-title {
    font-size: 20px;
    font-weight: 700;
}

p.flowpage-url-title {
    margin-bottom: 5px;
}


.Sidebar-Modal-end.width-extra .modal-dialog {
    max-width: 70% !important;
    width: 100%;
}

.template-image img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: top;
    position: relative;
}

.template-item {
    width: 300px;
    height: 450px;
}

.flow-btn-content {
    position: absolute;
    width: inherit;
    bottom: 20%;
    padding: 0px 20px;
}




/* mini page css start here  */

.mini-page-list {
    margin-top: 26px;
}

.mini-page-list-inner-item {
    background-color: #ffffff;
    border-radius: 30px 30px 10px 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    padding: 0px;
    margin-bottom: 16px;
}

.mini-page-list-inner-item-img {
    overflow: hidden;
    border: 5px solid #fff;
    border-radius: 30px 30px 0px 0px;
}

.mini-page-list-inner-item-img img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    object-position: top;
    /* border-radius: 30px 30px 0px 0px; */
}

.mini-page-list-inner-item-content {
    padding: 16px 16px 18px;
}

.mini-page-list-inner-item-content-title .page-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.mini-page-list-inner-item-content-title p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.mini-page-description p {
    font-size: 14px;
    color: #5b5e63;
    margin-bottom: 10px;
}

.mini-page-modal .modal-dialog {
    max-width: 850px !important;
}

.choose-template-list-inner-item {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
    padding: 0px;
    margin-bottom: 20px;
    /* width: 300px; */
    cursor: pointer;
}

.choose-template-list-inner-item.active {
    box-shadow: rgb(172, 185, 253) 0px 0px 0px 4px;
}

.choose-template-list-inner-item-img {
    /* width: 300px; */
    height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.choose-template-list-inner-item-img img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 6px 6px 0px 0px;
}

.choose-template-list-inner-item-img::-webkit-scrollbar {
    display: none;
}

.choose-template-list-inner-item-content {
    padding: 16px 16px 10px;
}

.choose-template-list-inner-item-content-title .page-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
}

select.custom-drop-downbtn2 {
    height: -moz-fit-content;
    height: fit-content;
    width: 400px;
}

/* .choose-template-list-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
} */


.inline-box {
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
}

.total-click h6 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #5d586c;
    text-align: center;
}

.list-total-deta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.qr-code-icon img {
    width: 120px;
    height: auto;
    margin-top: 20px;
    cursor: pointer;
}

.mini-page-list-inner-item-content-btn button {
    width: 100%;
    margin-right: 0 !important;
    border-radius: 0;
}

.SmartLinks-table-view .views04 h6:first-child {
    padding-left: 0;
}

.edit-title {
    background-color: #000;
    color: #fff;
    padding: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    border-radius: 50%;
    cursor: pointer;
}

.btn-class-right {
    text-align: right;
}

.actioin-text-res {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #fff;
    background: linear-gradient(90deg, #0b10e7 0%, #2adf63 100%);
    padding: 20px 0px;
    border-radius: 4px;
}

.actioin-text-res h4 {
    margin-bottom: 0;
}

.flex-page-tabs li.nav-item {
    width: 50%;
}

.flex-page-tabs li.nav-item .nav-link {
    width: 100%;
    color: #000;
}

.flex-page-tabs li.nav-item .nav-link.active {
    color: #fff;
    background: linear-gradient(90deg, #0b10e7 0%, #2adf63 100%);
    border: 1px solid #2adf63;
}

body.dark .flex-page-tabs li.nav-item .nav-link {
    color: #fff;
}

@media (max-width: 440px) {
    .default-color-picker-list {
        display: flex;
        box-sizing: border-box;
        cursor: inherit;
        max-width: 600px;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }
}