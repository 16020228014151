.guest-list-container {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .search-filter-container {
    background-color: #f8f9fa;
  }
  
  .search-container {
    position: relative;
  }
  
  .search-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
  }
  
  .guest-list {
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .guest-item {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;
    transition: background-color 0.2s;
  }
  
  .guest-item:hover {
    background-color: #f8f9fa;
  }
  
  .guest-info {
    display: flex;
    align-items: center;
    flex: 2;
  }
  
  .guest-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #6c757d;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 15px;
  }
  
  .guest-details {
    display: flex;
    flex-direction: column;
  }
  
  .guest-status {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .status-badge {
    font-size: 0.8rem;
    padding: 0.4rem 0.6rem;
  }
  
  .guest-date {
    flex: 1;
    text-align: center;
    color: #6c757d;
    font-size: 0.85rem;
  }
  
  .guest-actions {
    width: 50px;
    text-align: right;
  }
  
  .action-btn {
    border: none;
    /* background: transparent; */
  }
  
  .action-btn:hover {
    background-color: #e9ecef;
  }
  
  /* Status Badge Colors */
  .badge.bg-success {
    background-color: #28a745 !important;
  }
  
  .badge.bg-primary {
    background-color: #0d6efd !important;
  }
  
  .badge.bg-danger {
    background-color: #dc3545 !important;
  }
  
  .badge.bg-warning {
    background-color: #ffc107 !important;
  }
  
  .badge.bg-orange {
    background-color: #fd7e14 !important;
  }
  
  .badge.bg-purple {
    background-color: #6f42c1 !important;
  }
  
  .badge.bg-info {
    background-color: #20c997 !important;
  }
  
  @media (max-width: 768px) {
    .guest-item {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .guest-info {
      margin-bottom: 10px;
      width: 100%;
    }
    
    .guest-status, .guest-date, .guest-actions {
      width: 100%;
      justify-content: flex-start;
      margin-top: 5px;
      text-align: left;
    }
    
    .guest-actions {
      margin-top: 10px;
    }
  }